import * as React from 'react';

import { graphql } from 'gatsby';

import AboutInfo from '../components/AboutInfo';
import SiteTitle from '../containers/SiteTitle';
import Layout from '../layouts';

const About = ({
  location,
  data: {
    file: {
      image: { fixed },
    },
  },
}) => (
  <Layout expanded location={location}>
    <SiteTitle title="About Mikael Manukyan" />

    <AboutInfo fixed={fixed} />
  </Layout>
);

export const query = graphql`
  query Me {
    file(absolutePath: { glob: "**/me.jpg" }) {
      image: childImageSharp {
        fixed(width: 256, height: 256, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default About;
