import * as React from 'react';

import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { withPrefix } from 'gatsby';
import Img from 'gatsby-image';

import PageContainer from '../../layouts/Container';
import mq from '../../styles/mq';
import { rhythm } from '../../utils/typography';
/** @jsx jsx */
import { particlesJS } from '../../vendor/particles.js/particles';
import PageHeader from '../PageHeader';

const ContentContainer = styled('section')`
  position: relative;

  display: flex;
  flex-direction: column;

  ${mq.medium} {
    align-items: center;
    flex-direction: row;
  }
`;

const MeImage = styled(Img)`
  flex: 1 0 auto;

  border-radius: 50%;

  margin: 0 0 ${rhythm(1)} 0;
  align-self: center;

  ${mq.medium} {
    margin: 0 ${rhythm(1)} 0 0;
  }
`;

const Container = styled('div')`
  position: relative;
`;

const ParticlesContainer = styled('div')`
  position: absolute;
  top: 0;
  height: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
`;

const getParticlesConfig = theme => ({
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: theme.primaryColor,
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: theme.primaryColor,
      },
      polygon: {
        nb_sides: 5,
      },
      image: {
        src: 'img/github.svg',
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: theme.secondaryColor,
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 6,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'repulse',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 133.9851235911262,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
});

const About = ({ theme, fixed }) => {
  const particlesConfig = React.useMemo(() => getParticlesConfig(theme), [
    theme,
  ]);

  React.useEffect(() => {
    particlesJS('particles', particlesConfig);
  }, [particlesConfig]);

  return (
    <Container>
      <ParticlesContainer id="particles" />

      <PageContainer css={{ position: 'relative', zIndex: 2 }}>
        <PageHeader header="whoami" />
        <ContentContainer>
          <MeImage alt="Mikael Manukyan photo" fixed={fixed} />
          <div>
            <p>
              My name is <strong>Mikael (mee-ca-el) Manukyan</strong>. You can
              call me Michael or Misha (mee-sha) as all my friends call me.
              Examples: "Misha, let's discuss it.", "Misha, let's go to have a
              drink".
            </p>

            <p>
              My social links to:{' '}
              <a target="_blank" href="https://github.com/mike1808">
                GitHub mike1808
              </a>{' '}
              and{' '}
              <a target="_blank" href="https://github.com/mike1808">
                Twitter @mmm1808
              </a>
            </p>

            <p>
              I am a Software Engineer with over 6 years of experience. I am
              curious to challenge myself in small and big ways - from
              participating in hackathons and playing with Arduino to switching
              from Web Development to a new eld. In the past, in a very short
              time, I was able to gain pro ciency in Machine Learning and
              secured a position of ML Engineer.
            </p>
            <p>
              My colleagues describe me as attentive to details. I became a
              recognized leader in every team I worked. A big part of it is due
              to encouragement and mentorship I provided. I like sharing
              knowledge and I have experience teaching in universities and
              coding schools.
            </p>

            <p>
              You can find my full{' '}
              <a
                target="_blank"
                href={withPrefix('/Mikael Manukyan Resume.pdf')}
              >
                resume here.
              </a>
            </p>
          </div>
        </ContentContainer>
      </PageContainer>
    </Container>
  );
};

export default withTheme(About);
