import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Link } from 'gatsby';

import typography, { rhythm } from '../utils/typography';
import Navigation from './Navigation';

const HeaderContainer = styled('header')`
  display: flex;
  flex-flow: row wrap;

  justify-content: space-between;
  align-items: center;

  line-height: ${rhythm(2)};
  padding: ${rhythm(1 / 2)} ${rhythm(1)};
  background: ${props => props.theme.backgroundDefaultColor};
`;

const h1Styles = typography.toJSON().h1;
const h1Overrides = {
  fontSize: h1Styles.fontSize,
  fontWeight: h1Styles.fontWeight,
  lineHeight: h1Styles.lineHeight,
};

const fallbackFontStyles = css`
  font-family: Georgia, 'Times New Roman', serif;
  font-size: 2.2rem;
  font-weight: normal;
  letter-spacing: 2px;
  word-spacing: -6px;
  line-height: 34px;
`;

const HomeTitle = styled('h1')`
  ${css(h1Styles)};

  ${fallbackFontStyles};

  .wf-active & {
    ${css(h1Overrides)}
    font-family: 'Domine', sans-serif;

    letter-spacing: initial;
    word-spacing: initial;
  }

  flex: 1 1 0;
  margin: 0;
  margin-right: 50px;

  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }
`;

const Title = HomeTitle.withComponent('p');

const HomeLink = ({ title }) => <Link to="/">{title}</Link>;

const Header = ({
  theme,
  isHome,
  title,
  categories,
  onThemeSwitch,
  useDarkTheme,
}) => (
  <HeaderContainer theme={theme}>
    {isHome ? (
      <HomeTitle>
        <HomeLink title={title} />
      </HomeTitle>
    ) : (
      <Title>
        <HomeLink title={title} />
      </Title>
    )}

    <Navigation
      categories={categories}
      onThemeSwitch={onThemeSwitch}
      useDarkTheme={useDarkTheme}
    />
  </HeaderContainer>
);

Header.propTypes = {
  isHome: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  onThemeSwitch: PropTypes.func.isRequired,
  useDarkTheme: PropTypes.bool.isRequired,
};

export default withTheme(Header);
