import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { StaticQuery, graphql } from 'gatsby';

const SiteTitle = ({ title }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { title: siteTitle },
      },
    }) => <Helmet title={title ? `${title} | ${siteTitle}` : siteTitle} />}
  />
);

SiteTitle.propTypes = {
  title: PropTypes.string,
};

export default SiteTitle;
