import React from 'react';
import PropTypes from 'prop-types';
import useClickAway from 'react-use/lib/useClickAway';

import { ClassNames, css } from '@emotion/core';
import styled from '@emotion/styled';

import mq from '../styles/mq';
import { rhythm, scale } from '../utils/typography';
import NavLink from './NavLink';
import ThemeSwitch from './ThemeSwitch';

const NavMenuToggle = styled('button')`
  ${mq.large} {
    display: none;
  }

  -webkit-appearance: none;
  padding: 0;
  font: inherit;
  background: transparent;
  border: 0;
  outline: 0;

  position: absolute;
  top: 6px;
  right: ${rhythm(1)};

  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 11;

  span {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background-color: ${props => props.theme.primaryColor};
    font-size: 0px;
    -webkit-touch-callout: none;
    user-select: none;
    transition: background-color 300ms;
  }

  span::before,
  span::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    transition: transform 300ms;
    background-color: ${props => props.theme.primaryColor};
  }

  span::before {
    transform: translateY(-250%);
  }

  span::after {
    transform: translateY(250%);
  }
`;

const Nav = styled('nav')`
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  ul {
    display: flex;

    ${mq.maxLarge} {
      display: flex;
      flex-flow: column wrap;

      transition: transform 300ms;
      transform: translateX(100%);

      position: fixed;
      z-index: 10;

      background: ${props => props.theme.backgroundPrimaryColor};

      right: 0;
      top: 0;
      height: 100vh;

      padding: ${rhythm(3)} ${rhythm(1)};

      ${css(scale(1))};
    }
    margin: 0;
    padding: 0;

    ${css(scale(-1 / 5))};
  }

  li {
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-left: ${rhythm(1)};

    ${mq.maxLarge} {
      margin: 0;
    }
  }

  ${mq.maxLarge} {
    &.open ul {
      transform: translateX(0);
    }

    &.open ${NavMenuToggle} {
      position: fixed;

      span {
        background-color: transparent;
      }

      span::before {
        transform: translateY(0) rotate(45deg);
      }

      span::after {
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
`;

function Navigation({ theme, useDarkTheme, onThemeSwitch }) {
  const [open, setOpen] = React.useState(false);

  const ref = React.useRef(null);

  useClickAway(ref, () => {
    if (open) {
      toggle();
    }
  });

  const toggle = React.useCallback(() => {
    setOpen(open => !open);
  }, []);

  return (
    <ClassNames>
      {({ cx }) => (
        <Nav ref={ref} className={cx({ open })} theme={theme}>
          <NavMenuToggle theme={theme} onClick={toggle}>
            <span>Menu</span>
          </NavMenuToggle>
          <ul>
            <li>
              <ThemeSwitch value={useDarkTheme} onChange={onThemeSwitch} />
            </li>
            <li>
              <NavLink exact to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/posts">Posts</NavLink>
            </li>
            <li>
              <NavLink to="/talks">Talks</NavLink>
            </li>
            <li>
              <NavLink to="/notes">Notes</NavLink>
            </li>
            <li>
              <NavLink to="/tags">Tags</NavLink>
            </li>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
          </ul>
        </Nav>
      )}
    </ClassNames>
  );
}

Navigation.propTypes = {
  onThemeSwitch: PropTypes.func.isRequired,
  useDarkTheme: PropTypes.bool.isRequired,
};

export default Navigation;
