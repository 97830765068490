import React from 'react';

import { ClassNames, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import { Link } from 'gatsby';

const activeLink = css`
  &::after {
    height: 3px !important;
    opacity: 1 !important;
    transform: translateY(0px) !important;
  }
`;

const isPartiallyActive = activeClassName => ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: activeClassName } : null;
};

const isActive = activeClassName => ({ isCurrent }) => {
  return isCurrent ? { className: activeClassName } : null;
};

const NavLink = ({ theme, exact, ...restProps }) => {
  return (
    <ClassNames>
      {({ css, cx }) => {
        const base = css`
          display: block;
          text-decoration: none;
          color: inherit;

          position: relative;
          padding: 0 0 5px;

          &::after {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${theme.accentColor};
            content: '';
            opacity: 0;
            transition: height 0.3s, opacity 0.3s, transform 0.3s;
            transform: translateY(-10px);
          }

          &:hover {
            ${activeLink};
          }
        `;

        const active = cx([base, css(activeLink)]);

        return (
          <Link
            className={base}
            getProps={(exact ? isActive : isPartiallyActive)(active)}
            {...restProps}
          />
        );
      }}
    </ClassNames>
  );
};

export default withTheme(NavLink);
