import 'prism-themes/themes/prism-ghcolors.css';

import React from 'react';
import { Helmet } from 'react-helmet';
import useLocalStorage from 'react-use/lib/useLocalStorage';

import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import { StaticQuery, graphql } from 'gatsby';

import SiteMetadataContext from '../containers/SiteMetadataContext';
import themeDark from '../styles/themeDark';
import themeLight from '../styles/themeLight';
import { rhythm, scale } from '../utils/typography';
import Body from './Body';
import Container from './Container';
import Footer from './Footer';
import Header from './Header';

const globalStyles = css`
  html, body {
    font-family: "Source Sans 3", sans-serif;
  }
  
  .gatsby-highlight-code-line {
    background-color: #eaeaea;
    display: block;

    margin-right: -1em;
    margin-left: -1em;

    padding-right: 1em;
    padding-left: 0.75em;

    border-left: 0.25em solid black;
  }

  .gatsby-highlight {
    background-color: white;
    margin: ${rhythm(0.5)} 0;
    padding: ${rhythm(1)};
    overflow: auto;
  }

  .gatsby-highlight pre[class*='language-'] {
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: initial;
    float: left;
    min-width: 100%;
    border: none;
  }

  kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
      0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
    color: #333;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
  }
`;

function TemplateContent({ data, location, children, expanded }) {
  const [useDarkTheme, setUseDarkTheme] = useLocalStorage(
    'useDarkTheme',
    false
  );
  const theme = React.useMemo(() => (useDarkTheme ? themeDark : themeLight), [
    useDarkTheme,
  ]);
  const onThemeChange = React.useCallback(value => setUseDarkTheme(value), [
    setUseDarkTheme,
  ]);

  const siteMetadata = data.site.siteMetadata;
  const siteTitle = data.site.siteMetadata.title;
  const siteAuthor = data.site.siteMetadata.author;
  const categories = (data.allMarkdownRemark.group || []).map(
    ({ fieldValue }) => fieldValue
  );

  let header;

  let rootPath = `/`;

  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`;
  }

  return (
    <SiteMetadataContext.Provider value={siteMetadata}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <Global
          styles={{
            body: {
              color: theme.primaryColor,
            },
            a: {
              color: theme.accentColor,
            },
            'a:hover, a:active': {
              color: theme.primaryColor,
            },
          }}
        />
        <Helmet>
          <meta name="theme-color" content={theme.backgroundDefaultColor} />
        </Helmet>
        <Body>
          <Header
            isHome={location.pathname === rootPath}
            title={siteTitle}
            categories={categories}
            onThemeSwitch={onThemeChange}
            useDarkTheme={useDarkTheme}
          />
          <Container expanded={expanded}>{children}</Container>
          <Footer author={siteAuthor} />
        </Body>
      </ThemeProvider>
    </SiteMetadataContext.Provider>
  );
}

function Template(props) {
  return (
    <StaticQuery
      query={graphql`
        query TemplateQuery {
          site {
            siteMetadata {
              title
              author
              disqusShortname
            }
          }
          allMarkdownRemark(
            limit: 2000
            filter: { frontmatter: { draft: { ne: true } } }
          ) {
            group(field: frontmatter___category) {
              fieldValue
            }
          }
        }
      `}
      render={data => <TemplateContent {...props} data={data} />}
    />
  );
}

export default Template;
