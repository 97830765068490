import {
  DEFAULT_WIDTH,
  LARGE_DISPLAY_WIDTH,
  MOBILE_WIDTH,
  TABLET_WIDTH,
} from 'typography-breakpoint-constants';

const mq = {
  // Numerical values will result in a min-width query
  small: parseInt(MOBILE_WIDTH),
  medium: parseInt(TABLET_WIDTH),
  large: parseInt(DEFAULT_WIDTH),
  xLarge: parseInt(LARGE_DISPLAY_WIDTH),
  maxLarge: 'max-width: 991px',
};

export const breakpoints = mq;

export default Object.keys(mq).reduce((accumulator, label) => {
  let prefix = typeof mq[label] === 'string' ? '' : 'min-width:';
  let suffix = typeof mq[label] === 'string' ? '' : 'px';

  accumulator[label] = `@media (${prefix + mq[label] + suffix})`;

  return accumulator;
}, {});
