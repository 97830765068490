import React from 'react';

import styled from '@emotion/styled';

import { rhythm } from '../utils/typography';

const maxWidth = rhythm(36);

const Container = styled('main')`
  flex: 1;

  max-width: ${props => (props.expanded ? 'none' : maxWidth)};
  width: ${props => (props.expanded ? 'auto' : maxWidth)};
  padding: ${props => (props.expanded ? 0 : rhythm(1))};
  margin: ${props => (props.expanded ? 0 : '0 auto')};

  @media (max-width: ${maxWidth}) {
    max-width: 100%;
  }
`;

export default Container;
