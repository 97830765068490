import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { scale } from '../utils/typography';

const Header = styled('h1')(scale(1));

const PageHeader = ({ header, className }) => (
  <Header className={className}>{header}</Header>
);

PageHeader.propTypes = {
  header: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PageHeader;
