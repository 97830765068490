import React from 'react';

import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';

const Body = styled('div')`
  background: ${props => props.theme.backgroundPrimaryColor};

  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export default withTheme(Body);
